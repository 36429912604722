























import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import OilTank from './OilTank.vue';

@Component
export default class OilfoxDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private readonly oilTank = OilTank;

  private get fillColor(): string {
    const value = this.metrics.continuous.fillingLevelPercentage?.value;
    if (value === undefined) {
      return 'GREY';
    }
    if (value < 0.15) {
      return 'RED';
    }
    if (value < 0.25) {
      return 'ORANGE';
    }

    return 'GREEN';
  }

  private get fillingLevelPercentage(): number {
    return this.metrics.continuous.fillingLevelPercentage?.value ?? 0;
  }
}
