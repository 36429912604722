




































import Id from '@/features/ui/mixins/id';
import { EnumProp, NumberProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import { scaleLinear, ScaleLinear } from 'd3-scale';

@Component
export default class OilTank extends Mixins(Id) {
  @EnumProp('GREY', 'ORANGE', 'RED', 'GREEN')
  private readonly fillColor!: string;

  @NumberProp()
  private readonly value?: number;

  private get rectHeight(): number {
    return this.scale(this.value ?? 0);
  }

  private get scale(): ScaleLinear<number, number> {
    return scaleLinear().range([11, 84]).domain([0, 1]);
  }
}
